import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { finLoading, iniLoading } from '../store/loadingSlice';

// COMPONENTES
import PanelPresentacionSitio from '../components/site/PanelPresentacionSitio';
import PanelPresentacionServicio from '../components/site/PanelPresentacionServicio';
import PanelPresentacionPagina from '../components/site/PanelPresentacionPagina';
import PanelImagenTextoHorizontal from '../components/site/PanelImagenTextoHorizontal';
import PanelFilaCards from '../components/site/PanelFilaCards';
import PanelTituloTextoHorizontal from '../components/site/PanelTituloTextoHorizontal';
import PanelCardTextoEnlaces from '../components/site/PanelCardTextoEnlaces';
import PanelFilaResumenes from '../components/site/PanelFilaResumenes';
import PanelListaPreguntas from '../components/site/PanelListaPreguntas';
import PanelPilaTextos from '../components/site/PanelPilaTextos';
import PanelListaColumnas from '../components/site/PanelListaColumnas';
import PanelListaDocumentos from '../components/site/PanelListaDocumentos';
import PanelTabsEnlaces from '../components/site/PanelTabsEnlaces';
import PanelCotizaciones from '../components/site/PanelCotizaciones';
import { setTitle } from '../store/titleSlice';

/**
 * Página dinámica!
 */
function SitePage() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const backend = useSelector((state) => state.env.backend);
  const [pagina, setPagina] = useState(null);
  const navigate = useNavigate();

  // fetch pagina
  useEffect(() => {
    let codigo = searchParams.get('page') || 'index';
    dispatch(iniLoading());
    // console.log(`${backend}/api/paginas?codigo=${codigo}`);
    axios
      .get(`${backend}/api/paginas?codigo=${codigo}`)
      .then((response) => {
        let res = response.data;
        if (Array.isArray(res.data) && res.data.length == 1) {
          if (res.data[0].visible) {
            setPagina(res.data[0]);
            window.scrollTo(0, 0); // scroll top
            dispatch(setTitle(res.data[0].nombre)); // window title
          } else {
            console.log('Página inactiva!');
            navigate('/404');
          }
        } else {
          navigate('/404');
        }
      })
      .catch((error) => {
        // no hacer toast xq es publico
        console.error(error);
      })
      .finally(() => {
        dispatch(finLoading());
      });
  }, [searchParams]);

  // construir componente from db
  const construirComponente = (comp) => {
    if (comp.visible) {
      let muestra = JSON.parse(comp.muestra);
      switch (comp.codigo) {
        case 'cmp_pres_sit':
          return <PanelPresentacionSitio muestra={muestra} />;
        case 'cmp_pres_pag':
          return <PanelPresentacionPagina muestra={muestra} />;
        case 'cmp_pres_serv':
          return <PanelPresentacionServicio muestra={muestra} />;
        case 'cmp_img_txt_hrz':
          return <PanelImagenTextoHorizontal muestra={muestra} />;
        case 'cmp_fil_cards':
          return <PanelFilaCards muestra={muestra} />;
        case 'cmp_tit_txt_hrz':
          return <PanelTituloTextoHorizontal muestra={muestra} />;
        case 'cmp_crd_txt_els':
          return <PanelCardTextoEnlaces muestra={muestra} />;
        case 'cmp_fil_ress':
          return <PanelFilaResumenes muestra={muestra} />;
        case 'cmp_list_pregs':
          return <PanelListaPreguntas muestra={muestra} />;
        case 'cmp_pila_txts':
          return <PanelPilaTextos muestra={muestra} />;
        case 'cmp_list_cols':
          return <PanelListaColumnas muestra={muestra} />;
        case 'cmp_list_docs':
          return <PanelListaDocumentos muestra={muestra} />;
        case 'cmp_tabs_els':
          return <PanelTabsEnlaces muestra={muestra} />;
        case 'cmp_cots':
          return <PanelCotizaciones muestra={muestra} />;
        default:
          return <div>not found</div>;
      }
    } else {
      return null;
    }
  };

  // component will unmount / limpiar nuestro titulo
  useEffect(() => {
    return () => {
      dispatch(setTitle(''));
    };
  }, []);

  return (
    <>
      {/* construirComponente */}
      <div>
        {pagina &&
          pagina.componentes.map((comp) => <div key={comp.id}>{construirComponente(comp)}</div>)}
      </div>

      {/* placeholder */}
      {!pagina && (
        <div style={{ height: '480px' }} className="d-flex content-center items-center">
          <p>Bienvenido a Finexpar! Cargando página...</p>
        </div>
      )}
    </>
  );
}

export default SitePage;
