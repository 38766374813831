import ImBrandWhite from '../assets/img/brand-white.png';

import CategoriaFooter from '../components/CategoriaFooter';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { finLoading, iniLoading } from '../store/loadingSlice';

import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

function SiteFooter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backend = useSelector((state) => state.env.backend);
  const captchaSiteKey = useSelector((state) => state.env.captchaSiteKey);

  const captchaRef = useRef(null);
  const [touchedForm, setTouchedForm] = useState(false);
  const [categorias, setCategorias] = useState(null);
  const [emailSuscripcion, setEmailSuscripcion] = useState('');

  // fetch categoria
  useEffect(() => {
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/categorias?posicion=footer`)
      .then((response) => {
        let res = response.data;

        if (Array.isArray(res)) {
          setCategorias(res);
        } else {
          navigate('/404');
        }
      })
      .catch((error) => {
        // no hacer toast xq es publico
        console.error(error);
      })
      .finally(() => {
        dispatch(finLoading());
      });
  }, []);

  const onChangeEmail = (email) => {
    setEmailSuscripcion(email);
    setTouchedForm(true);
  };

  const suscribirse = async () => {
    dispatch(iniLoading());

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (
      emailSuscripcion &&
      emailSuscripcion.toString().match(/.*\@.*\.\w{2,3}/g) !== null &&
      emailSuscripcion.charAt(0) != '@'
    ) {
      if (!token) {
        toast.error('Complete el captcha.');
        return dispatch(finLoading());
      }

      try {
        let response = await axios.post(`${backend}/api/suscripciones`, {
          email: emailSuscripcion,
          token: token,
        });

        let res = response.data;
        if (res.ok) {
          toast.success('Suscripcion realizada correctamente.');
          setEmailSuscripcion('');
          setTouchedForm(false);
        } else {
          toast.error(res.error || 'Error al realizar la suscripción.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Error al realizar la suscripción.');
      }

      dispatch(finLoading());
    } else {
      toast.error('Correo electrónico no válido');
      dispatch(finLoading());
    }
  };

  return (
    <footer>
      <div className="container">
        <CategoriaFooter lista={categorias} />
        <div className="footer-contact">
          <div>
            <h5>Conectá con nosotros</h5>
            <div className="social-nav">
              <a href="https://www.facebook.com/Finexpar" target="_blank">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/finexpar/" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://linkedin.com/company/finexpar/mycompany" target="_blank">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
          <div>
            <h5>Suscribite a nuestras novedades</h5>
            <div className="newsletter">
              <input
                type="email"
                value={emailSuscripcion}
                onChange={(e) => {
                  onChangeEmail(e.target.value.toLowerCase());
                }}
                maxLength={50}
                placeholder="ejemplo@ejemplo.com.py"
              />
              {touchedForm && (
                <ReCAPTCHA
                  style={{ marginBottom: '20px' }}
                  sitekey={captchaSiteKey}
                  ref={captchaRef}
                />
              )}
              <button type="button" onClick={suscribirse} disabled={!emailSuscripcion}>
                Suscribirme
              </button>
            </div>
            <p id="news-warn">
              Nos encantaría informarte sobre nuestros productos, novedades y ofertas por correo
              electrónico. Puede retirar su consentimiento en cualquier momento siguiendo el enlace
              para darse de baja. Consulte nuestro Aviso de privacidad para saber cómo procesamos
              sus datos personales.
            </p>
          </div>
        </div>
        <div className="footer-copyright">
          <img src={ImBrandWhite} className="logo" />
          <p>
            © {new Date().getFullYear()} Finexpar S.A.E.C.A.
            <br />
            Avda. Mcal. López 837 y Mary Lions Asunción Paraguay
          </p>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
