import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './layouts/Loading';
import UsuarioSesion from './components/UsuarioSesion';
import ModalGlobal from './components/tmp/ModalGlobal';
import Banner from './assets/img/banner_zeta.jpg';

/**
 * App React
 */
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const title = useSelector((state) => state.title.value);

  // State variable to control the visibility of the modal
  const [isModalOpen, setIsModalOpen] = useState(true);

  // location change use effect
  useEffect(() => {
    // redireccion a la pagina de inicio
    if (location.pathname == '/') {
      navigate('/site');
    }

    // hide menu mobile
    if (document.querySelector('.main-nav')) {
      document.querySelector('.main-nav').classList.remove('show');
    }
  }, [location]);

  // window title use effect
  useEffect(() => {
    if (title.trim()) {
      window.document.title = title + ' | Finexpar';
    } else {
      window.document.title = 'Finexpar';
    }
  }, [title]);



  return (
    <div>
      <UsuarioSesion />
      <Outlet />
      <ToastContainer pauseOnFocusLoss={false} />
      <Loading />

      {/* Use the ModalGlobal component */}
      {isModalOpen && (
        <ModalGlobal
          src={Banner}
          url="https://www.zbanco.com.py"
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default App;
