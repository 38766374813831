// import MenuParaVos from '../components/tmp/MenuParaVos';
import BrandPrimary from '../assets/img/brand-primary.png';
import CategoriaNavbar from '../components/CategoriaNavbar';

import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { finLoading, iniLoading } from '../store/loadingSlice';
import { authLogout } from '../store/authSlice';
import { toast } from 'react-toastify';

function SiteHeader() {
  const dispatch = useDispatch();
  const backend = useSelector((state) => state.env.backend);
  const navigate = useNavigate();
  const [categorias, setCategorias] = useState(null);
  const authData = useSelector((state) => state.auth.value);
  const rol = authData ? authData.user.rol : '';

  const roles = {
    admin: 'Administrador',
    rrhh: 'RRHH',
    calidad: 'Calidad',
    comercial: 'Comercial',
  };

  // fetch categoria
  useEffect(() => {
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/categorias?posicion=navbar`)
      .then((response) => {
        let res = response.data;

        if (Array.isArray(res)) {
          setCategorias(res);
        } else {
          navigate('/404');
        }
      })
      .catch((error) => {
        // no hacer toast xq es publico
        console.error(error);
      })
      .finally(() => {
        dispatch(finLoading());
      });
  }, []);

  // logout handler
  const logout = () => {
    let usu = authData.user.name;
    axios.post(`${backend}/api/logout`);
    dispatch(authLogout());
    toast.success('Log out correcto de ' + usu);
  };

  const showMenu = () => {
    document.querySelector('.main-nav').classList.toggle('show');
  };

  return (
    <header>
      <div className="container nav-container">
        <div className="d-flex items-center">
          <a href="/">
            <img src={BrandPrimary} className="logo" />
          </a>
          <nav className="main-nav" role="menubar">
            {categorias &&
              categorias.map((cat, index) => (
                <li key={cat.id} className="dropdown-toggle">
                  <div href="#" className="dropdown-title">
                    {cat.nombre} <i className="fas fa-angle-down"></i>
                  </div>
                  <CategoriaNavbar key={cat.id} lista={cat.categorias_hijo_rec_visible} />
                </li>
              ))}
          </nav>
        </div>
        <div className="online">
          <div className="current-clients text-right">
            {!authData ? (
              <>
                {/*<Link to="/login" className="text-primary">
                  Ingresar <i className="fas fa-fw fa-sign-in-alt"></i>
                </Link>i*/}
                {/* <a id="solicitud-clave" href="#" className="text-muted">
                  Solicitar acceso
                </a> */}
                <a
                  href="https://secure.finexpar.com.py/homebanking/index.php/site/login"
                  target="_blank"
                >
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAArCAYAAABGgMc7AAAAGXRFWH
RTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3NpVFh0WE1MOmNvbS5hZG9iZS54bXAAAA
AAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+ID
x4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3
JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZj
pSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbn
MjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy
5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3
hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb2
0veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpkNTY2ZjQxOS0wN2
RjLTQ5ZDgtYTFmMy1iZWVmNTlhNmQzMWEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkQ1Qk
MzMzhGNUJEMTFFNDlCQzNBMThCNUVENEQyRDIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RT
E0Qjg5Q0VGNUJDMTFFNDlCQzNBMThCNUVENEQyRDIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUG
hvdG9zaG9wIENDIChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3Rhbm
NlSUQ9InhtcC5paWQ6ZDU2NmY0MTktMDdkYy00OWQ4LWExZjMtYmVlZjU5YTZkMzFhIiBzdFJlZj
pkb2N1bWVudElEPSJ4bXAuZGlkOmQ1NjZmNDE5LTA3ZGMtNDlkOC1hMWYzLWJlZWY1OWE2ZDMxYS
IvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IG
VuZD0iciI/PhSHneMAAAjbSURBVHja7FwJbFRFGJ4qJNxbEWo9YFsxEAnQpSggBrsViIkGKEETD5
QleEQMYcGEaAC7DaBiVNbgkRAM2yhRYyIFI8agdJcYEJSy5VQi6RbksEHYlragxeD8j39e/87Ou7
q7hcj7kz/vvXlz7Xzzn/PanMuXLzOX/l90g7sELqguuaC65ILqkguqS2rqZvZyW16RqrivVTsH1M
a5WfXiwYZaF51sgEqoF+dXOc/lfGuG53Cc8zrOqzj/7ULSNaACoNWcx4qC3mNHsrxHH9ErXDh+gv
21aStrO3basjNo23NoIbtwpI617N4PRYM4V3CejPyPC0v2QV1OAR360Qp2x8ypKZUuvhBgBxdXsM
Yt200BveeLtezG3r1YXeQzVncFVEETOS/hXC4KcnJy2OjRo6MG3UXwGpDL9+7dG5HahXiZ/kzeBX
l5nD/7+H3YYJwgcgE+l/E2SewHysvIPPzSfBKc4zinJO1Ump82D/JONR+oH6mpqUmkC2o3VLkaDV
r6kg7oxYYz7NSWrazn7fksf0op65E3gPk+WMV2lMwwlNgRa1ZqgJrQcxRUpBKDulGD96ryCAGFvs
slV6NxcrHP9QTkEF94KA9x9nDexEFJ8LICqR+4n431fVAHQSuT6gWwX2YyH3guLy4unsGBrUrH+/
XipDW6bepD2vXMzl/YjhGTWN3iN9mhp4JsT2C+Vg6A5T8zU9kRSHjvQq/VJgN7PcDgXQzVtOAoX6
QQ3gtaiGUpv4MvZMCm9qqUxkmA5OP4GqgIaJisTVDRTwVp45HqCOlulJ6N5lMpaY60JFUpVg3fd9
SIoHKT+w+z3JF3s279+qbUz5//tFJlOxmTgKiSWCHdccX7RlzUEFHZZhShqlpazL3YVxglUANPSC
AlMVe+AZLYxqcAFeazADddgaofVLlRLqHQvkjSOOnHqbvGTdPCnNNrPkl51+OWgYZ2dNiiedo9AA
/cxRR2KK0BXi8kmIAUJxIzm2wYI1vMUKI9UlkZKYO29VbSSgAVdjr7yQewtWBTgc5Gdyjt6L8trW
z/nGA6w5TzBblM2G+zXZSoQWELzWg2Sn65wr7LmiIoO0CSI3RO4dgJ8GpRMqsMHD5B1agh5E2adp
xqSJ6HH2B3zp2l21rq/VI7enDZG7ZCnixRCBfHa8MmVZpIQ0DxbKTSqaPzHtrlDhKJmkCo0yITFa
zbaSsnKW1QAVDweEESW+rq2eH5S5R2FMKXM59uTheYCgObaklgI/mCxXChAdRaos5s2VT0bMWGEO
1LQKUTwGRHKYqOVkKheosUcyhTSOJCHNeL7y3XoNPqF0CjgP5a8bYuidSOQuhzft8hNmDWNI2797
niB0EopD0Pzu8qaQ0QT7Sok7bZg3bUj8Bq5SqVDhsQNockedTrjRGuN1HBcVJexO1rKCuSWvjWK6
ww8IR2D4DWPPZ8B9UKGSMRj4KtHfXu8tRNwWNb4H2LlnVaitGu0p0NNrNDIE8WGeLISuLkGFE1r0
efS/E6XYAIdhQTD9XEs7bjMAg/oIr3ESC/A9quFipYboTer65p+L1pEsKxpA7fENYBPb21OgVQoL
azSd3TlRkcJiHB8Ax10yC/JHUlFjFfqJPjCPVaLzYRqmjhgC2wctwwS+SVEiSCqhTSbKZpIhmTVF
C5IF0CUEg8qAicpRqDdGHxD59r8SxkoyB5YYNKTVz5iGKBElK7uCSto0kmKU6upQbjJ8iCJiRvN0
AcnYTBfAQlVXMi86K/M6UuSCaXUDp3Q8ox+/CMx6Qj+WWfLgZ1O3W1ahRvNh/5nR15calhnwJULf
erBhV28zE4eoPc7/VMnf0o0LakglND87YATFfSTz36FmBWxofSEB9/8XzSoj5wkteLS++gj1xeHl
W0y8UxUtoZ1Ovg1Mhz4vX8qndW48D8d/Xsp8oeJcZdaEpkBNTG7bs1p8aKrGzk0Xc+ZN3752pHbw
4ADWM6TU9EwJR4eZAvSMTEBol6Pl4vIXmyJby8VAGsDx2gGHFsVCTqyaYiqkgeAG2S7KXVOAFF8k
OESqGMgArOUAZiTdOjOQNAI8RjFYkBPzpF6/n7hEriaDiNIJZlUnPAmLApCGilFvOYDlJrUUdFMW
mjWLbvxq5hQhUqAJ3BF6SKvAvhTg4r1GCmFtQOsPq9TS+6wOEwUa5uHXnt1/qHZ0It1VJApRxoEd
ons53OmL0TmmwShENe3IxZJUegQi63i6nASOWg01FL7JPhTu/KBbWR0QpabMKUdtxhihL2ZRTUPk
PvuloLknRYnqkFzaiqRq3hYTZOWqQQr4Sw5fwd29RRmz5mPfMHsobYDi3OhFBnyPxntXeiDGLRS8
0t2mF6v+HDtM0AOd+TX3/Hmmr2syEvz2tPgU163InE2i1X2T6RZgtfZWmtQz8hYbNNRdZt6tGVYe
2wPK9kgvYMgEKqEMpuGuPTkvlAcGJzflecNR36TQPuAH8eWDqR9fdPYOf2xLWz1ZNV3+j1DSgu2V
bZifI6cFKEtM624VhlS1oTrP3zm2C2xnEMKn7WydqaW/WryP0CWJDMFyEQ1O0/fgwbt2sz8y648v
3aH+9HtBOaCbGNLG+y39LzY1dONLwYq9LAPUzCHKcL6rmK0iq+dvBcLVBbrToAtSqOz0BSaVIBVP
OlpmZNik9s+Eoru3n6FNbw7TYWK7yPnfu5psP3w/KY6AwJ1bMAYlLOUVRd0xHwUCcW1A75YCzKck
aJOl5wb8de429yIqWyoxRI16bWs/YPt9TqeM06VvzlWk1iQVJbyLe8kIUaPOdJ3cYCwUffI9eHtX
LYEAfIwTqnU5zPSIsQBjAREC9rP+mADE1QyhSZOlOwoJCF4rcbbThaHmb82ajwuMulLFfUTnIAwj
Ni4+04Sl4nyQerhD5cVmdT/0sEB6+vwY1RQh9TfnF2HVA2E/ogSvdzvjfLv+FHzits/Ki4+w8N0n
eUWtH7fJ3zn1mYwwnW/rc07t/RZIDsxqmtKLHAXfKnjC51nnLc/85yfapfl1xQXXJBdckF1SVr+k
+AAQCB4thgaCdAagAAAABJRU5ErkJggg=="
                  />
                </a>
              </>
            ) : (
              <>
                <Link to="/" className="text-primary" onClick={logout}>
                  Log out <i className="fas fa-fw fa-sign-out-alt"></i>
                </Link>
                <Link to="/admin" className="text-muted">
                  {rol in roles ? roles[rol] : '-'} <i className="fas fa-fw fa-cog"></i>
                </Link>
              </>
            )}
          </div>
          <div id="new-client">
            <a href="/site/producto" className="btn btn-primary">
              Quiero ser cliente
            </a>
          </div>
        </div>
        <div id="mobile-toggle" onClick={showMenu}>
          <span>
            <i className="fas fa-bars"></i>
          </span>
        </div>
      </div>
    </header>
  );
}

export default SiteHeader;
