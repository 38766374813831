import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelCardTextoEnlaces
 * codigo = cmp_crd_txt_els
 */
function PanelCardTextoEnlaces({ muestra }) {
  let background = `url(${getImageUrl(muestra.srcImgBg)})`;

  return (
    <section className="tipos-prestamos">
      <div className="container">
        <div className="tipo-prestamo" style={{ background, marginBottom: '3em' }}>
          <div className="tipo-prestamo__content">
            <h2 className="mb-1" style={{ textAlign: muestra.titulo_justify ? 'justify' : 'left' }}>
              {muestra.titulo}
            </h2>
            <p className="mb-4" style={{ textAlign: muestra.texto_justify ? 'justify' : 'left' }}>
              {muestra.texto}
            </p>
            <div className="actions">
              {muestra.enlaces.map((enl, i) => (
                <a
                  href={enl.enlace}
                  key={i}
                  className={`btn ${i == 0 ? 'btn-light' : 'btn-outline-light'}`}
                >
                  {enl.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Setting default values for the props of component
PanelCardTextoEnlaces.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelCardTextoEnlaces.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    texto: PropTypes.string.isRequired,
    enlaces: PropTypes.arrayOf(
      PropTypes.shape({
        enlace: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    srcImgBg: PropTypes.string.isRequired,
  }).isRequired,
};

export default PanelCardTextoEnlaces;
