import Modal from 'react-modal';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');
const modalStyle = {
  content: {
    marginTop: '65px',
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    content: {
      position: 'absolute',
      top: '40px',
      left: '40px',
      right: '40px',
      bottom: '40px',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px'
    },
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    maxHeight: '80%',
  },
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: 'rgba(170,169,169,0.58)',
  color: 'rgba(255, 255, 255, 0.9)',
  width: '30px',
  height: '30px',
  textAlign: 'center',
  lineHeight: '30px',
  fontSize: '20px',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  boderRadius: '50%',
  cursor: 'pointer',
  boxShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
  transition: 'background-color 0.3s ease',
};

const imageStyle = {
  // transform: 'scale(0.5)',
  width: '100%', // Added this line
  height: '100%', // Added this line
  objectFit: 'contain', // Added this line
};

const ModalGlobal = ({ src, url, onClose }) => {

  const imgRef = useRef(); // Create a ref for the image

  // useEffect(() => {
  //   if (imgRef.current) {
  //     modalStyle.content.width = `${imgRef.current.offsetWidth}px`;
  //   }
  // }, [imgRef]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={modalStyle}
      contentLabel="Example Modal"
    >
      <button onClick={onClose}  style={closeButtonStyle}>X</button>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={src} alt="imagen" style={imageStyle} />
      </a>
    </Modal>
  )
}

ModalGlobal.propTypes = {
  src: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalGlobal;