import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelPresentacionPagina
 * codigo = cmp_pres_pag
 */
function PanelPresentacionPagina({ muestra }) {
  return (
    <>
      {muestra.srcImgBg && (
        <>
          {muestra.label || muestra.titulo ? (
            <section
              className="inner-hero"
              style={{
                background: `url(${getImageUrl(muestra.srcImgBg)}) no-repeat center`,
                backgroundSize: 'cover',
              }}
            ></section>
          ) : (
            // si viene solo la imagen, se muestra la imagen plana
            <div>
              <img loading="lazy" src={getImageUrl(muestra.srcImgBg)} alt="imagen" style={{ width: '100%' }} />
            </div>
          )}
        </>
      )}
      {(muestra.label || muestra.titulo) && (
        <section className="container text-center header-section">
          {muestra.label && <h4 className="font-weight-regular">{muestra.label}</h4>}
          {muestra.titulo && <h1>{muestra.titulo}</h1>}
        </section>
      )}
    </>
  );
}

// Setting default values for the props of component
PanelPresentacionPagina.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelPresentacionPagina.propTypes = {
  muestra: PropTypes.shape({
    srcImgBg: PropTypes.string, // opcional, ejemplo: 'http://localhost:8000/img/hero-bg.png'
    label: PropTypes.string, // opcional
    titulo: PropTypes.string, // opcional
  }).isRequired,
};

export default PanelPresentacionPagina;
